<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 105px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              {{ $t("labels.time") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.tracking") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.customer") }}
            </th>
            <th role="columnheader" class="text-center">
              {{ $t("labels.quantity") }}
            </th>
            <th role="columnheader" class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" v-for="item in items" :key="item.id">
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>{{ item.tracking_id }}</td>
            <td>
              {{
                item.dc_distributor_name ||
                `Kho: ${item.warehouse_destination_code}`
              }}
            </td>
            <td>{{ formatNumber(item.total_item) }}</td>
            <td>
              <v-btn x-small color="success" @click="startPickup(item)">
                {{ $t("labels.pick") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { debounce, formatDateTime, formatNumber } from "@/libs/helpers";

export default {
  name: "PickupWaitList",
  components: {},
  props: {},
  data: () => ({
    items: [],
    isLoading: false,
  }),
  mounted() {
    this.getList();
  },
  methods: {
    formatDateTime,
    formatNumber,
    getList: debounce(function () {
      httpClient.post("/dc/v1/pickup-get-order-list").then(({ data }) => {
        this.items = [...data];
      });
    }, 500),
    async startPickup(order) {
      if (!confirm(this.$t("messages.are_you_sure"))) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      try {
        const { data } = await httpClient.post(`/dc/v1/pickup-start`, order);
        this.isLoading = false;
        this.$emit("onStarted", data);
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>
